import * as THREE from 'three';
import { useEffect, useRef } from "react";

const vertexShader = `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

const fragmentShader = `
  #define t iTime
  #define r iResolution.xy
  uniform float iTime;
  uniform vec3 iResolution;
  varying vec2 vUv;

    void main() {
        vec3 c;
        float l, z = iTime;
        vec2 fragCoord = vUv * iResolution.xy;
        for (int i = 0; i < 3; i++) {
            vec2 uv, p = fragCoord.xy / iResolution.xy;
            uv = p;
            p -= 0.5;
            p.x *= iResolution.x / iResolution.y;
            z += 0.07;
            l = length(p);
            uv += p / l * (sin(z) + 1.0) * abs(sin(l * 9.0 - z - z));
            c[i] = 0.01 / length(mod(uv, 1.0) - 0.5);
        }
        gl_FragColor = vec4(c / l, iTime);
    }
`;

function Shader() {
    const refContainer = useRef(null);
    useEffect(() => {

        // Detect device type
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent);
        const isTablet = /tablet|ipad/.test(userAgent);
        const isDesktop = !isMobile && !isTablet;


        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.z = 1;

        const renderer = new THREE.WebGLRenderer();
        renderer.setPixelRatio(window.devicePixelRatio);
		renderer.setSize(window.innerWidth, window.innerHeight);
        // Use ref as a mount point of the Three.js scene instead of the document.body
		refContainer.current && refContainer.current.appendChild( renderer.domElement );

        const shaderMaterial = new THREE.ShaderMaterial({
            uniforms: {
              iResolution: { value: new THREE.Vector3(window.innerWidth, window.innerHeight, 1) },
              iTime: { value: 0 }
            },
            vertexShader: vertexShader,
            fragmentShader: fragmentShader
        });

        // Set plane dimensions based on device type to fit screen
        // TODO: Find a better way to handle this
        var planeWidth = 0;
        var planHeight = 0;

        if(isDesktop) {
            planeWidth = 3.75
            planHeight = 2;
        }
        else {
            planeWidth = 2;
            planHeight = 3.75;
        }

        const geometry = new THREE.PlaneGeometry(planeWidth, planHeight);
        const plane = new THREE.Mesh(geometry, shaderMaterial);
        scene.add(plane);

        function animate() {
            requestAnimationFrame(animate);
            shaderMaterial.uniforms.iTime.value += 0.010;
            renderer.render(scene, camera);
        }
          
        animate();

        // Handle window resize
        const handleResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
            //shaderMaterial.uniforms.iResolution.value.set(window.innerWidth, window.innerHeight, 1);
        };
  
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };

    }, []);

    return (
		<div ref={refContainer}></div>
	);
}

export default Shader;