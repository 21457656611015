import './App.css';
import Shader from './Components/Shader/Shader.js';

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<Shader />
			</header>
		</div>
	);
}

export default App;
